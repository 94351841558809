import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_ALIANZAS+'/visitas');

export default {
  obtener_logs(options) {
    let url = utils.url_options(`/`, options)

    if (options.columns)
        url += '&columns='+options.columns.join(',');

    return http.get(url);
  },
  obtener_log(id) {
    return http.get(`/${id}`);
  },
  exportar_logs(payload) {
    return http.post('/exportar', payload, {responseType: 'blob'});
  }
}