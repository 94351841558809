<template>
  <Modal :options="{close:true}" @close="$emit('close')">
    <div class="title">Log: {{ log.uuid.substring(0,30)+'...' }}</div>
    <div class="body">
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">ID</label>
        <div class="col-sm-9">
          <div class="view-code">{{ log._id }}</div>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">UUID</label>
        <div class="col-sm-9">
          <div class="view-code">{{ log.uuid }}</div>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">Origen</label>
        <div class="col-sm-9">
          <div class="view-code">{{ log.origen }}</div>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">Origen IP</label>
        <div class="col-sm-9">
          <div class="view-code">{{ log.origen_ip }}</div>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">Utm</label>
        <div class="col-sm-9">
          <div class="view-code">{{ log.utm ? log.utm : '--' }}</div>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">Url</label>
        <div class="col-sm-9">
          <div class="view-code">{{ log.url }}</div>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">Evento</label>
        <div class="col-sm-9">
          <div class="view-code">{{ log.evento }}</div>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">Headers</label>
        <div class="col-sm-9">
          <pre class="view-code">{{ encode_string(log.headers) }}</pre>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">Request</label>
        <div class="col-sm-9">
          <pre class="view-code">{{ encode_string(log.request) }}</pre>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">Response</label>
        <div class="col-sm-9">
          <pre class="view-code">{{ encode_string(log.response) }}</pre>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">Response Code</label>
        <div class="col-sm-9">
          <div class="view-code">{{ log.response_code }}</div>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">Created</label>
        <div class="col-sm-9">
          <div class="view-code">{{ log.created_at }}</div>
        </div>
      </div>
    </div>
    <div class="footer text-right">
      <button class="btn btn-info mr-2" @click="descargar_json">Descargar</button>
      <button class="btn btn-primary" @click="$emit('close')">Cerrar</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';

import api from '@/apps/alianzas_logs/api/logs.js';

export default {
  components: {
    Modal
  },
  props: {
    id: String
  },
  data() {
    return {
      log: {
        uuid: '--',
        origen: '--',
        origen_ip: '--',
        url: '--',
        evento: '--',
        request: '--',
        headers: '--',
        response: '--',
        response_code: '--',
        created_at: '--',
      }
    }
  },
  mounted() {
    this.obtener_log();
  },
  methods: {
    async obtener_log() {
      try {
        this.$log.info('obtener_log');
        this.log = (await api.obtener_log(this.id)).data;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    encode_string(text) {
      if (typeof text != 'string')
        text = JSON.stringify(text, null, 4);

      return text;
    },
    descargar_json() {
      let json = JSON.stringify(this.log, null, 4);

      let blob = new Blob([json], { type: "application/json;charset=utf-8" });
      let downloadUrl = URL.createObjectURL(blob);
      let temp_link = document.createElement('a');
      temp_link.href = downloadUrl;
      temp_link.setAttribute('download', 'log_'+this.log._id+'.json');
      temp_link.click();
    }
  }
}
</script>

<style lang="scss" scoped>
.view-code {
  border: solid 1px rgb(206, 206, 206);
  border-radius: 5px;
  padding: 10px;
  word-break: break-all;
  overflow-wrap: break-word;
}
</style>